.doc-viewer{
  height: 500px;
  width: 100%;
}

#pdf-download {
  display: none
}

#header-bar{
  display: none
}
