.googleAddressInput{
    font-size: 14px!important;
}


.autocomplete-dropdown-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  position: absolute;
  z-index: 1900;
  border-radius: 0 0 2px 2px;
}

.suggestion-item, .suggestion-item--active {
    width: "50%";
    max-width: 420px;
    min-width: "100%";
    height: 45px;
    border: 1px rgb(184, 184, 184) solid;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 6px 20px 6px 6px;
  }
