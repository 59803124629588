.modal {
  z-index: 1350 !important;
}

.modal-backdrop {
  z-index: 1300 !important;
}

.glow-button {
  font-family: "Lato", sans-serif;
  border: 0;
  outline: 0 !important;
  background: none;
  color: unset;
  border-radius: 5px;
  font: inherit;
  cursor: pointer !important;
}

.glow-dark-blue-color {
  color: #1f5eaa !important;
}

.bg-glow-dark-blue-color {
  background-color: #1f5eaa !important;
}

.glow-modal-title {
  font-weight: 500!important;
  font-size: 1.2rem!important;
}

.max-h-750px {
  max-height: 750px;
}

.required:after {
  content: "*";
  position: relative;
  font-size: inherit;
  color: #f1416c;
  padding-left: 0.25rem;
  font-weight: 700;
}